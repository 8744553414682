import React from "react"
import { Link } from "gatsby"
import Img from "gatsby-image"

export default ({title, excerpt, slug, image}) => (
  <Link to={`${slug}`}>
      <div className="h-full bg-white rounded shadow-lg">
        <div>
          {image && <Img className="hidden md:flex" fluid={image} />}
          <div className="px-6 py-4">
            <div className="font-bold text-xl mb-2">{title}</div>
            <p className="text-gray-700 text-base">{excerpt}</p>
          </div>
        </div>
      </div>
  </Link>

)